<template>
  <div>
    <v-card>
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{ $t('historylog') }}</div>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="1">
              <v-autocomplete
                class="mt-2"
                dense
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="GetLogHistoryChangePerPage(itemsPerPage)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2"></v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="pt-0">
              <div style="max-width: 100%">
                <label class="for--text">วันที่เริ่มต้น</label>
                <v-menu
                  ref="menu_start"
                  v-model="menu_start"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="StartDate"
                      @change="
                        (v) => {
                          StartDate = v;
                          CheckValueStartDate(StartDate);
                        }
                      "
                      outlined
                      dense
                    >
                      <template v-slot:append>
                        <div
                          v-on="on"
                          style="
                            background-color: #1f3781;
                            width: 39px;
                            height: 40px;
                            cursor: pointer;
                            margin-top: -8px;
                            margin-right: -11px;
                            border-radius: 3px;
                          "
                        >
                          <img
                            src="@/assets/Mask Group 27.png"
                            width="20"
                            style="margin-left: 10px; margin-top: 10px"
                          />
                        </div>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="en"
                    v-model="date_start"
                    :allowed-dates="allowedDates_start"
                    no-title
                    @change="CheckValueStartDate(date_start)"
                    @input="menu_start = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="2" class="pt-0">
              <label class="for--text">วันที่สิ้นสุด</label>
              <v-menu
                v-model="menu_end
                "
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="EndDate"
                    @change="
                      (v) => {
                        EndDate = v;
                        CheckValueEndDate(EndDate);
                      }
                    "
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <div
                        v-on="on"
                        style="
                          background-color: #1f3781;
                          width: 39px;
                          height: 40px;
                          cursor: pointer;
                          margin-top: -8px;
                          margin-right: -11px;
                          border-radius: 3px;
                        "
                      >
                        <img
                          src="@/assets/Mask Group 27.png"
                          width="20"
                          style="margin-left: 10px; margin-top: 10px"
                        />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="en"
                  v-model="date_end"
                  :allowed-dates="allowedDates_end"
                  @change="CheckValueEndDate(date_end)"
                  no-title
                  @input="menu_end
                   = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="1">
              <v-btn @click="GetLogHistorySearch(search)" width="100%" color="#1976d2 " class="white--text mt-3"
                >{{$t('search')}}</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                class="mt-2"
                dense
                v-model="search"
                v-on:keyup.enter="GetLogHistorySearch(search)"
                outlined
                :placeholder="$t('search')"
                placeholder-color="#ACACAC"
                prepend-inner-icon="mdi-magnify"
                color="#126496"
              />
            </v-col>
          </v-row>
          <div>
            <v-data-table
              :headers="translatedHeaders"
              :items="HistoryLogList"
              hide-default-footer
              style="color: #444444"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              :search-input.sync="search"
              single-line
              hide-details
            >
              <!-- <template v-slot:item.download="{ item }" class="text-end">
                <v-btn
                  text
                  color="#126496"
                  style="text-transform: none !important"
                  @click="downloadURI(item.certFile, item.certImageName)"
                  :disabled="!flag_dowload"
                >Download</v-btn>
              </template> -->
            </v-data-table>
          </div>
          <v-row>
            <v-col cols="12">
              <v-pagination
                class="__pagination"
                v-model="page"
                :total-visible="6"
                :length="pageCount"
                @input="GetLogHistoryChangePage(page)"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>

    <Loading :flagLoading="flagLoadingSend" />
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },
  data: () => ({
    url: enurl.apiUrl,
    permissionid: store.state.permissionid,
    menu_start: false,
    menu_end
    : false,
    StartDate: "",
    EndDate: "",
    date_start: new Date().toISOString().substring(0, 10),
    date_end: new Date().toISOString().substring(0, 10),
    allowDates_start: [],
    allowDates_end: [],
    flag_dowload: false,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [10, 15, 20],
    HistoryLogList: [],
    time: "",
    flagLoadingSend: false,
    headers: [
      {
        text: "Date",
        sortable: false,
        value: "date",
      },
      {
        text: "Time",
        sortable: false,
        value: "time",
      },
      {
        text: "Username",
        sortable: false,
        value: "username",
      },
      {
        text: "CertName",
        sortable: false,
        value: "certName",
      },
      {
        text: "CertReason",
        sortable: false,
        value: "certReason",
      },
      {
        text: "CertLocation",
        sortable: false,
        value: "certLocation",
      },
    ],
  }),
  computed: {
    translatedHeaders() {
      return [
        { text: this.$t('date'), sortable: false, value: 'date' },
        { text: this.$t('time'), sortable: false, value: 'time' },
        { text: this.$t('username'), sortable: false, value: 'username' },
        { text: this.$t('certName'), sortable: false, value: 'certName' },
        { text: this.$t('certReason'), sortable: false, value: 'certReason' },
        { text: this.$t('certLocation'), sortable: false, value: 'certLocation' },
      ];
    },
  },
  methods: {
    allowedDates_start(val){
      var self = this;
      if (self.EndDate == '') {
        return true;
      }else{
        var moment_val = moment(val,'YYYY-MM-DD');
        var moment_end = moment(self.EndDate,'DD/MM/YYYY');
        
        var month_diff = moment_end.diff(moment_val,'month') < 3;
        
        
        var end_after_val = moment_end.isAfter(moment_val);
        return month_diff && end_after_val;
      }
    },
    allowedDates_end(val){
      var self = this;
      if (self.StartDate == '') {
        return true;
      }else{
        var moment_val = moment(val,'YYYY-MM-DD');
        var moment_start = moment(self.StartDate,'DD/MM/YYYY');
        var month_diff = moment_val.diff(moment_start,'months') < 3;
        var start_after_val = moment_val.isAfter(moment_start);
        return month_diff && start_after_val;
      }
    },
    DateFormat(value) {
      return moment(value)
        
        .format("DD/MM/YYYY");
    },
    CheckValueStartDate(value) {
      if (value == "") {
        this.date_start = "";
      } else {
        if (this.EndDate == "") {
          this.StartDate = this.DateFormat(value);
        } else {
          const ArrEdate = this.EndDate.split("/");
          const Edate =
            parseInt(ArrEdate[2])+ "-" + ArrEdate[1] + "-" + ArrEdate[0];
          // console.log(Edate);
          if (new Date(Edate) >= new Date(value)) {
            this.StartDate = this.DateFormat(value);
          } else {
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend =
              "ฝรุณาเลือฝวันที่สิ้นสุดให้มาฝฝว่าวันที่เริ่มต้น";
            self.flagAlertSend = true;
            // alert("Please select an end date greater than the start date.");
            this.date_start = "";
          }
        }
      }
    },
    CheckValueEndDate(value) {
      if (value == "") {
        this.date_end = "";
      } else {
        if(this.StartDate == ""){
          this.EndDate = this.DateFormat(value);
        }else{
          const ArrSdate = this.StartDate.split("/");
          const Sdate = (parseInt(ArrSdate[2])) + "-" + ArrSdate[1] + "-" + ArrSdate[0];
          // console.log(Sdate);
          if(new Date(Sdate) <= new Date(value)){
            this.EndDate = this.DateFormat(value);
          }else{
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = "ฝรุณาเลือฝวันที่สิ้นสุดให้มาฝฝว่าวันที่เริ่มต้น";
            self.flagAlertSend = true;
            // alert("Please select an end date greater than the start date.");
            this.date_end = "";
          }
        }
      }
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    GetLogHistory: function () {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: 1,
        perPage: 10,
        search: self.search == "" ? null : self.search,
        startDate: self.StartDate,
        endDate: self.EndDate
      };
      axios
        .post(`${self.url}Log/GetLogHistoryAll`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            try {
              // let a = 0;
              // for (let i = 0; i < response.data.data.length; i++) {
              //   try {
              //     response.data.data[i].data != "null" ? response.data.data[i].data.includes("certReason") ? JSON.parse(response.data.data[i].data).certReason : "1" : ""
              //     a = a+1;
              //   }
              //   catch(err) {
              //     console.log(a);
              //     console.log(err);
              //   }
              // }
              self.HistoryLogList = response.data.data.listData.map((v) => ({
                id: v.id,
                date: v.date,
                time: v.time,
                username: v.username,
                certName: v.certName,
                certReason: v.certReason,
                certLocation: v.certLocation,
                // certName: v.certID != null ? v.certName : v.data != "null" ? v.data.includes("empCert") ?
                //   (JSON.parse(v.data).empCert != null ? JSON.parse(v.data).empCert.certName : "") :
                //   (v.data.includes("certName") ? JSON.parse(v.data).certName : "") : "",
                // certReason: v.certReason != "" && v.certReason != null ? v.certReason : v.data != "null" ? v.data.includes("certReason") ? JSON.parse(v.data).certReason : "" : "",
                // certLocation: v.certLocation != "" && v.certLocation != null ? v.certLocation : v.data != "null" ? v.data.includes("certLocation") ? JSON.parse(v.data).certLocation : "" : "",
              }));
              self.pageCount = response.data.data.count;
            } catch (err) {
              console.log(err);
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            //alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },
    GetLogHistoryChangePage(val) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: val,
        perPage: self.itemsPerPage,
        search: self.search == "" ? null : self.search,
        startDate: self.StartDate,
        endDate: self.EndDate
      };
      axios
        .post(`${self.url}Log/GetLogHistoryAll`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            try {
              self.HistoryLogList = response.data.data.listData.map((v) => ({
                id: v.id,
                date: v.date,
                time: v.time,
                username: v.username,
                certName: v.certName,
                certReason: v.certReason,
                certLocation: v.certLocation,
                // certName: v.certID != null ? v.certName : v.data != "null" ? v.data.includes("empCert") ?
                //   (JSON.parse(v.data).empCert != null ? JSON.parse(v.data).empCert.certName : "") :
                //   (v.data.includes("certName") ? JSON.parse(v.data).certName : "") : "",
                // certReason: v.certReason != "" && v.certReason != null ? v.certReason : v.data != "null" ? v.data.includes("certReason") ? JSON.parse(v.data).certReason : "" : "",
                // certLocation: v.certLocation != "" && v.certLocation != null ? v.certLocation : v.data != "null" ? v.data.includes("certLocation") ? JSON.parse(v.data).certLocation : "" : "",
              }));
              self.pageCount = response.data.data.count;
            } catch (err) {
              console.log(err);
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            //alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },
    GetLogHistoryChangePerPage(val) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: 1,
        perPage: val,
        search: self.search == "" ? null : self.search,
        startDate: self.StartDate,
        endDate: self.EndDate
      };
      axios
        .post(`${self.url}Log/GetLogHistoryAll`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            try {
              self.HistoryLogList = response.data.data.listData.map((v) => ({
                id: v.id,
                date: v.date,
                time: v.time,
                username: v.username,
                certName: v.certName,
                certReason: v.certReason,
                certLocation: v.certLocation,
                // certName: v.certID != null ? v.certName : v.data != "null" ? v.data.includes("empCert") ?
                //   (JSON.parse(v.data).empCert != null ? JSON.parse(v.data).empCert.certName : "") :
                //   (v.data.includes("certName") ? JSON.parse(v.data).certName : "") : "",
                // certReason: v.certReason != "" && v.certReason != null ? v.certReason : v.data != "null" ? v.data.includes("certReason") ? JSON.parse(v.data).certReason : "" : "",
                // certLocation: v.certLocation != "" && v.certLocation != null ? v.certLocation : v.data != "null" ? v.data.includes("certLocation") ? JSON.parse(v.data).certLocation : "" : "",
              }));
              self.pageCount = response.data.data.count;
              self.page = 1;
            } catch (err) {
              console.log(err);
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            //alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },
    GetLogHistorySearch(val) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: 1,
        perPage: self.itemsPerPage,
        search: val == "" ? null : val,
        startDate: self.StartDate,
        endDate: self.EndDate
      };
      axios
        .post(`${self.url}Log/GetLogHistoryAll`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            try {
            if(response.data.data.count == 0){
              self.HistoryLogList = [];
            }else{
              self.HistoryLogList = response.data.data.listData.map((v) => ({
                id: v.id,
                date: v.date,
                time: v.time,
                username: v.username,
                certName: v.certName,
                certReason: v.certReason,
                certLocation: v.certLocation,
                }));
            }
             
              self.pageCount = response.data.data.count;
              self.page = 1;
            } catch (err) {
              console.log(err);
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            //alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },
    UTCToLocal(dateStr, format) {
      if (typeof dateStr == "string") {
        let newDateStr = dateStr;
        if (!newDateStr.match(/Z$/)) {
          newDateStr += "Z";
        }
        return moment(newDateStr).format(format);
      }
      return "";
    },
  },
  mounted() {
    var today = new Date();
    this.EndDate = this.DateFormat(today);
    
    var threemonthsago = today.setMonth(today.getMonth() - 3);
    this.StartDate = this.DateFormat(threemonthsago);

    this.date_start =new Date(threemonthsago).toISOString().substr(0, 10);
    
    this.GetLogHistory();
    const elements = document.querySelectorAll(".v-pagination__navigation");
    // console.log(elements);
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = this.$t('next');
      } else {
        element.textContent = this.$t('previous');
      }
    }
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  * >>> .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
* >>> tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
* >>> .v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
* >>> .v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>